<template>
  <div class="main-wrapper">
    <section class="main_banner_area_winapp text-white" style="min-height: 100px">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="info">
              <h1 class="display-4 mb-3 bold">
                <i class="icon-home"></i> About Us
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-5 col-md-6 mr-md-auto" style="padding-top: 80px;">
            <div class="text-center">
              <div class="section-heading mb-4">
                <h2>" YOUR SUCCESS IS OUR SUCCESS "</h2>
              </div>
              <p style="font-size: 18px;">
                เพราะเราตระหนักดีว่าลูกค้าต้องการ
                <span style="font-weight: bold;color: #41c9a2;font-size: 25px;"
                  >ความสำเร็จ</span
                >
                ในฐานะผู้ช่วยลูกค้า
                เรามีหน้าที่ประสานขั้นตอนการทำงานของลูกค้าให้มีประสิทธิภาพ
                และสะดวกสบายอย่างสูงสุดเพราะความสำเร็จของลูกค้า
                คือเป้าหมายของเรา
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-sm-12">
            <div class="feature-img text-center">
              <v-lazy-image
                src="assets/images/windows/WindowsBase.jpg"
                alt="About Biosoft"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="clients_testimonial_area bg-light" id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Heading Text -->
            <div class="section-heading text-center">
              <h2>HPS INTELLIGENCE Co., Ltd.</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="slider slider-for">
              <!-- Client testimonial Text  -->
              <div class="client-testimonial-text text-center">
                <div class="client">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
                <div class="client-description text-center">
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #41c9a2;font-size: 25px;"
                  >เพราะความสำเร็จของท่าน... คือความสำเร็จของเรา</span>
                    เป็นวิสัยทัศน์ที่เรายึดถือมากว่า 15 ปี ด้วยความมุ่งมันตั้งใจในการนำเสนอ Solution งานด้าน HR เราจึงร่วมกันผลิตและพัฒนาซอฟแวร์ Biosoft
                    โดยกลุ่มนักพัฒนาซอฟต์แวร์ชาวไทยในนามบริษัท <span style="font-weight: bold;color: #41c9a2;">บริษัท เอชพีเอส อินเทลลิเจนซ์ จำกัด</span>
                  </p>
                </div>
                <div class="client-description text-center" style="padding-top: 50px">
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #41c9a2;"
                  >บริษัท เอชพีเอส อินเทลลิเจนซ์ จำกัด (HPS INTELLIGENCE Co., Ltd.)</span>
                  เริ่มก่อตั้งปี พ.ศ. 2548 โดยถือกำเนิดขึ้นโดยมีวัตถุประสงค์เพื่อเป็นผู้ผลิต/จัดจำหน่ายซอฟต์แวร์ (Software) และให้บริการครบวงจรด้านธุรกิจในประเทศไทย 
                  รวมทั้งดำเนินกิจกรรมทางด้านการตลาด สิ่งนี้เป็นผลจากการทุ่มเทความพยายามตลอดหลายปีที่ผ่านมา เพื่อให้ลูกค้าได้รับความพึงพอใจจากประสิทธิภาพ รูปลักษณ์(Image)  
                  และความน่าเชื่อถือในผลิตภัณฑ์และบริการของเรา ด้วยความมุ่งมั่นในการปฏิบัติหน้าที่อย่างเข้มแข็งและเอาใจใส่ ตลอดจนการบรรลุซึ่งกลยุทธ์ทางการตลาด ส่งผลให้บริษัทฯ 
                  สามารถตอบสนองซอฟต์แวร์และให้บริการต่างๆ แก่ทุกธุรกิจได้อย่างตรงตามความต้องการ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="clients_testimonial_area" id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Heading Text -->
            <div class="section-heading text-center">
              <h2>เป้าหมาย (GOAL)</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="slider slider-for">
              <!-- Client testimonial Text  -->
              <div class="client-testimonial-text text-center">
                <div class="client">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
                <div class="client-description text-center">
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #41c9a2;font-size: 25px;"
                  >เอชพีเอส</span>
                    ยังคงมุ่งมั่นในการพัฒนาผลิตภัณฑ์ บริการและแนะนำนวัตกรรม (Innovation) ใหม่ๆ เพื่อสนองความต้องการของลูกค้าในอนาคต 
                    การสร้างความพึงพอใจแก่ลูกค้ายังเป็นสิ่งสำคัญที่ <span style="font-weight: bold;color: #41c9a2;">บริษัท เอชพีเอส อินเทลลิเจนซ์ จำกัด</span>
                    ยึดมั่น รวมทั้งยังคงมุ่งมั่นพัฒนาการขาย สร้างประสิทธิภาพในบริการทั้งก่อนและหลังการขาย และค้นคว้าเพื่อเข้าถึงความต้องการอย่างแท้จริงของลูกค้า
                    <span style="font-weight: bold;color: #41c9a2;">เอชพีเอส</span> เชื่อมั่นในระบบการจัดการแบบใหม่ๆ ที่เกิดขึ้นจากการผสมผสาน รวมถึงการประยุกต์เพื่อนำมาซึ่งความเหมาะสม ดังนั้นเราจึงพยายามสร้างรูปแบบการจัดการที่เหมาะสม ซึ่งเป็นการผสานวัฒนธรรมองค์กรเข้าด้วยกัน
                  </p>
                </div>
                <div class="client-description text-center" style="padding-top: 50px">
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #41c9a2;"
                  >เรามีลูกค้ากว่า 400 บริษัท</span>
                  ที่ไว้วางใจให้ Biosoft ร่วมเป็นส่วนหนึ่งในการขับเคลื่อนงานด้านทรัพยากรบุคคล ซึ่งครอบคลุมในหลากหลายประเภทธุระกิจ และอุตสาหกรรม เช่นการผลิตแปรรูปอาหาร 
                  การผลิตชิ้นส่วนอะไหล่ยานยนต์ การผลิตบรรจุภัณท์ธุรกิจการให้บริการอสังหาริมทรัพย์ การบริหารงานก่อสร้าง และอีกหลากหลายธุรกิจที่เราชาว Biosoft ภาคภูมิใจ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="clients_testimonial_area bg-light" id="testimonial">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- Heading Text -->
            <div class="section-heading text-center">
              <h2>ประกาศแจ้งเตือน</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="slider slider-for">
              <!-- Client testimonial Text  -->
              <div class="client-testimonial-text text-center">
                <div class="client">
                  <i class="fa fa-quote-left" aria-hidden="true"></i>
                </div>
                <div class="client-description text-center">
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #f1c40f;font-size: 25px;"
                  >ขอให้ลูกค้าระมัดระวังผู้แอบอ้างขายโปรแกรมแบบไม่ถูกต้อง</span>
                  เนื่องจากในช่วงเวลาที่ผ่านมา บริษัทเอชพีเอส อินเทลลิเจนซ์ จำกัด ได้รับทราบว่ามีบุคคล หรือองค์กรบางกลุ่ม แอบอ้างชื่อบริษัทฯ เพื่อจำหน่ายโปรแกรมบริหารงานบุคคลไบโอซอฟต์ (Biosoft) 
                  โดยมิได้รับอนุญาตจากทางบริษัทฯ ซึ่งการกระทำดังกล่าวเป็นการละเมิดสิทธิ์ของบริษัทฯ และอาจส่งผลเสียต่อการใช้งานโปรแกรมของท่าน
                  </p>
                </div>

                <div class="client-description text-center">
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #f1c40f;"
                  >เพื่อความปลอดภัยและความถูกต้องในการใช้งานโปรแกรม</span>
                  ทางบริษัทฯ ขอเรียนให้ท่านทราบว่า การจัดซื้อโปรแกรมบริหารงานบุคคลไบโอซอฟต์ (Biosoft) 
                  ควรดำเนินการผ่านช่องทางการจัดจำหน่ายที่ได้รับอนุญาตอย่างเป็นทางการจากบริษัทฯ เท่านั้น การซื้อโปรแกรมจากแหล่งที่ไม่ได้รับอนุญาต อาจทำให้ท่านไม่ได้รับสิทธิ์ในการอัปเดตหรือบริการสนับสนุน รวมถึงอาจเสี่ยงต่อการเกิดปัญหาด้านความปลอดภัยของข้อมูล
                  </p>
                </div>

                <div class="client-description text-center">
                  <p style="color: #636d75; font-size: 18px;">
                    <span style="font-weight: bold;color: #41c9a2;"
                  >ทางบริษัท เอชพีเอส อินเทลลิเจนซ์ จำกัด (HPS INTELLIGENCE Co., Ltd.)</span>
                  ขอแนะนำให้ท่านตรวจสอบแหล่งที่มาของโปรแกรมอย่างรอบคอบ หากมีข้อสงสัยหรือต้องการข้อมูลเพิ่มเติมเกี่ยวกับช่องทางการจัดจำหน่ายที่ถูกต้อง 
                  ท่านสามารถติดต่อฝ่ายบริการลูกค้าของเราได้ที่ช่องทางต่างๆตามรายละเอียดหน้าเว็บไซต์ของเรา
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <mdb-container class="col-lg-12">
            <video
              class="video-fluid z-depth-1"
              autoplay
              loop
              controls
              muted
              style="max-width: 100%;"
            >
              <source
                src="https://drive.google.com/uc?export=download&id=1eCbUuy-TWGsyQv9YeG7m6DdD0DkAm3E9"
                type="video/mp4"
              />
            </video>
          </mdb-container>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
const VLazyImage = () => import("v-lazy-image");
export default {
  name: "about",
  components: {
    VLazyImage,
  },
  mounted: function() {
    this.$nextTick(function() {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style scoped>
.v-lazy-image {
  filter: blur(10px);
  transition: filter 3s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
</style>
